import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogName:string;
  blogCategory:string;
  blogTitle:string;
  blogContent:string;
  readingTime:string;
  todayDate:string;
  blogContentUri: string;

  constructor(private http: HttpClient,
    private route: ActivatedRoute) { 
}
ngOnInit() {
  this.route.params.subscribe(params => {
    console.log(params);
    this.blogName = params['name'];
    console.log(this.blogName);
    this.getJSON().subscribe(data => {
      console.log(data);
      this.blogCategory =  data['blogCategory'];
      this.blogTitle = data['blogTitle'];
      // this.todayDate = data['todayDate'];
      this.blogContentUri = data['blogContentUri'];
      // this.blogContent = atob(escape(data['blogContent']));
      // console.log(this.blogContent);
      this.http.get(this.blogContentUri,{responseType: "text"}).subscribe(d => {
        this.blogContent = d;
      })
    });   
  });
  this.todayDate = formatDate(new Date(), "MMM dd yyyy", 'en');
  this.readingTime = '8';
}
  
  public getJSON(): Observable<any> {
    return this.http.get('assets/metadata/'+this.blogName+'.json');
  }

}
