import { RouterConfig } from '../interfaces/router-config.interface';

export const routerConfig: RouterConfig = {
  users: {
    base: 'users'
  },
  blogs: {
    base: 'blogs'
  }
};
